/* eslint-disable */
export const messages = {
  system_admin_sco2pes_financial_scope: "Scope",
  system_admin_sco2pes_financial_co2_type: "CO<sub>2</sub> Typ",
  system_admin_sco2pes_financial_co2_type_price: "CO<sub>2</sub> Typ Preis",
  system_admin_sco2pes_financial_co2_type_prices: "CO<sub>2</sub> Typen Preise",
  system_admin_sco2pes_financial_co2_type_prices_import_title: "CO<sub>2</sub> Type Preise importieren",
  system_admin_sco2pes_financial_co2_type_prices_import_description: "CO<sub>2</sub> Typen Preise können über eine Excel-Datei importiert werden. Verwenden Sie diese Excel-Vorlage für den Datei-Upload:",
  system_admin_sco2pes_co2_type_price_do_you_really_want_to_delete_the_co2_type_price: "Sind Sie sicher, dass Sie den Preis des CO<sub>2</sub> Typen löschen wollen?",
  system_admin_sco2pes_co2_type_price_deleted: "CO<sub>2</sub> Typen Preis gelöscht",
  system_admin_sco2pes_co2_type_price_versions_add_version: "Version hinzufügen",
  system_admin_sco2pes_co2_type_price_version_validation_1: "<i>Von</i> und <i>Bis</i> müssen gesetzt sein",
  system_admin_sco2pes_co2_type_price_version_validation_2: "<i>Bis</i> muss nach <i>Von</i> stehen",
  system_admin_sco2pes_co2_type_price_successfully_edited: "CO<sub>2</sub> Typ Preis erfolgreich bearbeitet",
  system_admin_sco2pes_co2_type_price_successfully_created: "CO<sub>2</sub>-Typ Preis erfolgreich erstellt",
  system_admin_sco2pes_co2_type_price_validations: "Validierungen",
  system_admin_sco2pes_co2_type_price_validation_1: "Beide Namen sind angegeben und ein CO<sub>2</sub>-Typ ist ausgewählt.",
  system_admin_sco2pes_co2_type_price_validation_3: "Alle Preise sind positiv.",
  system_admin_sco2pes_co2_type_price_meta_data: "Meta Daten",
  system_admin_sco2pes_co2_type_price_versions: "Versionen",
  system_admin_sco2pes_co2_type_price_validity: "Gültigkeit",
  system_admin_sco2pes_co2_type_price_delete_version: "Version löschen",
  system_admin_sco2pes_co2_type_price_price: "Preis",
  system_admin_sco2pes_co2_type_price_booking_warning: "Das Aktualisieren eines CO2-Preises hat keine Auswirkungen auf gesperrte Buchungen und abgeschlossene Berichte.",

  system_admin_sco2pes_affected_title: "Diese Unternehmen sind betroffen",
  system_admin_sco2pes_affected_subtitle: "Bevor Sie speichern, überprüfen Sie bitte die folgenden Unternehmen, die von diesen Änderungen betroffen sind.",
  system_admin_sco2pes_affected_none_title: "Von diesem Update sind keine Unternehmen betroffen",
  system_admin_sco2pes_affected_none_subtitle: "Sie können fortfahren, indem Sie auf die Schaltfläche „Speichern“ klicken.",
  system_admin_sco2pes_affected_company_name: "Unternehmen",
  system_admin_sco2pes_affected_financial_models: "Finanzmodelle",
  system_admin_sco2pes_affected_financial_bookings: "Finanzielle Buchungen",

  system_admin_sco2pes_financial_category: "CO<sub>2</sub> Kategorie",
  system_admin_sco2pes_financial_versions: "Versionen",
  system_admin_sco2pes_financial_price_per_unit: "Preis pro Einheit",
  system_admin_sco2pes_financial_price_per_unit_selected: "Preis pro {unit}",
  system_admin_sco2pes_financial_co2_type_deactivated: "Deaktivierter CO2-Typ",
  system_admin_sco2pes_financial_co2_type_active: "Aktiver CO2-Typ",
  system_admin_sco2pes_financial_co2_type_open: "CO2-Typ Auswahl öffnen",
};
