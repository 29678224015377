export enum CurrencyCode {
  EUR = "EUR",
  USD = "USD",
  CHF = "CHF",
  GBP = "GBP",
  PLN = "PLN",
  HUF = "HUF",
  CNY = "CNY",
  MXN = "MXN",
  INR = "INR",
  CZK = "CZK",
  SEK = "SEK",
  TRY = "TRY",
  RSD = "RSD",
  RON = "RON",
  BGN = "BGN",
  JPY = "JPY",
  KRW = "KRW",
  SGD = "SGD",
}
