/* eslint-disable */
// prettier-ignore

export const messages = {
  customer_scopes_suppliers_emission_relevant: "Emissionsrelevant",
  customer_scopes_suppliers_transport_routes: "Transportwege",
  customer_scopes_suppliers_transport_route: "Transport-Routen",
  customer_scopes_suppliers_edit_details: "Details bearbeiten",
  customer_scopes_suppliers_co2_relevant: "CO<sub>2</sub> Relevant",

  // Welcome
  customer_sco2pes_welcome: "Willkommen bei GLOBAL FOOTPRINT",
  customer_sco2pes_welcome_message: "Willkommen in Modul GLOBAL FOOTPRINT, dem Tool zur Berechnung Ihres Corporate Carbon Footprint (CCF).<br/> Mit Hilfe von GLOBAL FOOTPRINT können Sie zum einen Daten, die Sie bereits verbucht haben, auf Knopfdruck anzeigen und analysieren.<br/>Darüber hinaus können Sie für einen gewünschten Berichtszeitraum eigenständig Daten erfassen und Buchungen vornehmen.<br/>Als Ergebnis der CO2-Bilanzierung mit GLOBAL FOOTPRINT erhalten Sie einen Treibhausgasbilanz-Bericht entsprechend der Richtlinien des Greenhouse Gas Protocol Corporate Accounting and Reporting Standard (GHG Protocol).",
  customer_sco2pes_your_overview: "Ihre Übersicht",
  customer_sco2pes_your_feedback: "Ihr Feedback",
  customer_sco2pes_your_feedback_message: "Wir entwickeln unsere Software ständig weiter und verbessern sie. Daher freuen wir uns über jegliches Feedback und Vorschläge zur Verbesserung. Wir versuchen, diese so schnell wie möglich umzusetzen.",
  customer_sco2pes_give_feedback: "Feedback geben",
  customer_sco2pes_type_feedback: "Geben Sie hier Ihr Feedback ein...",
  customer_sco2pes_feedback_success: "Vielen Dank für das Teilen Ihrer Erkenntnisse!",

  // License info
  customer_ccf_license_required: "Zugang eingeschränkt: Lizenz notwendig",
  customer_ccf_license_info: "Ihr Unternehmen hat keine GLOBAL FOOTPRINT Lizenz.<br>Bei Interesse am Produkt zögern Sie nicht das GLOBAL CLIMATE Team zu kontaktieren.",
  customer_sco2pes_further_info: "Weitere Informationen stehen hier zur Verfügung:",

  customer_sco2pes_scopes_info: "GLOBAL FOOTPRINT Informationen",
  customer_sco2pes_scope_1_description: "Scope 1 umfasst direkte Treibhausgasemissionen, die direkt von einer Organisation verursacht werden. Dies beinhaltet in der Regel Emissionen aus Quellen, die sich innerhalb der Kontrolle des Unternehmens befinden. Typische Scope 1-Emissionen umfassen Verbrennungsprozesse von Brennstoffen wie Gas, Öl oder Kohle in firmeneigenen Anlagen und Fahrzeugen. Weitere Beispiele sind Emissionen aus industriellen Prozessen oder aus der Verbrennung von Abfällen. Scope 1-Emissionen werden in Tonnen CO<sub>2</sub>-Äquivalent (tCO<sub>2</sub>e) gemessen.",
  customer_sco2pes_scope_2_description: "Scope 2 bezieht sich auf indirekte Treibhausgasemissionen, die durch den Bezug und Verbrauch von elektrischer Energie, Dampf oder Wärme entstehen. Diese Emissionen werden nicht direkt vor Ort erzeugt, sondern durch die Erzeugung der Energie, die von einem Unternehmen genutzt wird. Scope 2-Emissionen entstehen beispielsweise bei der Verbrennung von fossilen Brennstoffen zur Stromerzeugung in Kraftwerken. Unternehmen erfassen diese Emissionen, indem sie den erworbenen Energieverbrauch mit spezifischen Emissionsfaktoren multiplizieren, die von den Energielieferanten bereitgestellt werden.",
  customer_sco2pes_scope_3_description: "Scope 3 umfasst alle weiteren indirekten Treibhausgasemissionen, die sich aus den Geschäftstätigkeiten eines Unternehmens ergeben, aber außerhalb seiner direkten Kontrolle liegen. Dieser Scope deckt die gesamte Lieferkette und andere externe Aktivitäten ab, einschließlich der Emissionen, die mit der Gewinnung und Herstellung von Materialien, dem Transport, der Nutzung oder Entsorgung von Produkten sowie der Geschäftsreisen und der täglichen Pendelverkehre der Mitarbeiter verbunden sind. Scope 3-Emissionen sind oft die größte Quelle der Treibhausgasemissionen eines Unternehmens. Die Erfassung und Bewertung von Scope 3-Emissionen erfordert die Zusammenarbeit mit Lieferanten und anderen relevanten Partnern.",
  customer_sco2pes_summary_info: "Durch die Kategorisierung der Treibhausgasemissionen in diese Scopes ermöglicht das GHG-Protokoll Unternehmen eine umfassende Erfassung und Berichterstattung ihrer Emissionen. Dies ermöglicht es Unternehmen, gezielt an der Reduzierung der Emissionen zu arbeiten, indem sie ihre internen Prozesse optimieren, auf erneuerbare Energien umsteigen, effizientere Transportmethoden anwenden und nachhaltigere Lieferketten aufbauen. Es trägt zur Transparenz und Vergleichbarkeit von Emissionsdaten bei und unterstützt Unternehmen bei der Entwicklung und Umsetzung von Klimaschutzstrategien.",

  // CO2 database
  customer_sco2pes_co2_database_title: "CO<sub>2</sub> Datenbank",
  customer_sco2pes_co2_database_subtitle: "Mit Hilfe Ihrer individuellen CO<sub>2</sub>-Datenbank, können Sie eigene CO<sub>2</sub>-Typen erstellen und verwalten. Des Weiteren finden Sie hier alle CO<sub>2</sub>-Typen, welche bereits für Sie erstellt worden sind.<br/>Sollten Ihnen für die Erstellung des CO<sub>2</sub>-Typen notwendige Details (wie Emissionen pro Einheit, Gewicht pro Einheit, Ursprung) bereits bekannt sein, können Sie über „NEUER  CO<sub>2</sub>-TYP“ eigene CO<sub>2</sub>-Typen erstellen.",
  customer_sco2pes_co2_database_new_co2_type: "Neuer CO<sub>2</sub> Typ",
  customer_sco2pes_co2_database_type_description: "Typ Beschreibung",
  customer_sco2pes_co2_database_unit: "Einheit",
  customer_sco2pes_co2_database_co2_equivalent: "CO<sub>2</sub> Äquivalent",
  customer_sco2pes_co2_database_emissions_per_unit: "Emissionen pro Einheit",
  customer_sco2pes_co2_database_weight_per_unit: "Gewicht (kg) pro Einheit",
  customer_sco2pes_co2_database_do_you_really_want_to_delete_the_co2_type: "Möchten Sie den CO<sub>2</sub> Typ wirklich löschen",
  customer_sco2pes_co2_database_description_in_english: "Beschreibung in Englisch",
  customer_sco2pes_co2_database_description_in_german: "Beschreibung in Deutsch",
  customer_sco2pes_co2_database_edit_co2_type: "CO<sub>2</sub> Typ bearbeiten",
  customer_sco2pes_co2_database_co2_type_successfully_created: "CO<sub>2</sub> Typ erfolgreich erstellt",
  customer_sco2pes_co2_database_co2_type_successfully_deleted: "CO<sub>2</sub> Typ erfolgreich gelöscht",
  customer_sco2pes_co2_database_co2_type_successfully_edited: "CO<sub>2</sub> Typ erfolgreich bearbeitet",
  customer_sco2pes_co2_database_origin_database: "Ursprungsdatenbank",
  customer_sco2pes_co2_database_origin_database_reference: "Referenz in der Ursprungsdatenbank",
  customer_sco2pes_co2_database_origin_database_description: "Für die Pflege der CO<sub>2</sub>-Typen und spätere Prüfungen ist es notwendig, einen Verweis auf die Herkunft des CO<sub>2</sub>-Äquivalents zu speichern.",
  customer_sco2pes_co2_database_versions: "Versionen",
  customer_sco2pes_co2_database_version: "Version",
  customer_sco2pes_co2_database_from: "Von",
  customer_sco2pes_co2_database_until: "Bis",
  customer_sco2pes_co2_database_dialog_date_not_valid: "<strong>Von</strong> muss vor oder gleich <strong>Bis</strong> sein",
  customer_sco2pes_co2_database_dialog_kg_not_valid: "Kg Äquivalent muss bei gewählter Einheit <strong>kg</strong> stets den Wert 1 besitzen",
  customer_sco2pes_co2_database_version_delete_confirmation_title: "Version löschen",
  customer_sco2pes_co2_database_version_delete_confirmation_message: "Wollen Sie die Version wirklich löschen?",
  customer_sco2pes_co2_database_no_version_message: "Mindestens eine Version wird benötigt",
  customer_sco2pes_co2_database_versions_not_valid: "Es muss stets exakt eine Version pro Buchungsperiode existieren (keine Überschneidungen, keine Lücken). Die erste Version must gültig von 0000-01 sein, die letzte Version muss gültig bis 9999-12 sein.",
  customer_sco2pes_co2_database_versions_kg_not_valid: "Kg Äquivalent muss bei gewählter Einheit <strong>kg</strong> stets den Wert 1 besitzen",
  customer_sco2pes_co2_database_new_version: "Neue Version",
  customer_sco2pes_co2_database_edit_version: "Version bearbeiten",
  customer_sco2pes_co2_database_created_at: "Erstellt am",
  customer_sco2pes_co2_database_versions_count: "Anzahl der Versionen",
  customer_sco2pes_co2_database_create_unit_booking: "Unit Buchung erstellen",
  customer_sco2pes_co2_database_amount: "Menge",
  customer_sco2pes_co2_database_distance: "Entfernung",
  customer_sco2pes_co2_database_order_id: "Buchungsnummer",
  customer_sco2pes_co2_database_supplier: "Lieferanten Filter",
  customer_sco2pes_dashboard_company_code: "Buchungskreis Filter",
  customer_sco2pes_co2_database_transport_route: "Transportwege",
  customer_sco2pes_co2_database_booking_creation_success_title: "CO<sub>2</sub> Buchung erfolgreich hinzugefügt!",
  customer_sco2pes_co2_database_booking_creation_success_show_bookings: "Buchungen anzeigen",
  customer_sco2pes_co2_database_distance_multiply_description: "Die Gesamtdistanz ist das Product von Menge und Distanz",
  customer_sco2pes_co2_database_co2_type_desc: "Emissionsrelevante Daten können über die Zeit versioniert werden. Versionen können in der zeitlichen Auflösung von Monaten erstellt werden. Versionen müssen alle möglichen Monate abdecken, beginnend von „Start“ bis „Ende“.",
  customer_sco2pes_co2_database_type_valid_versions: "Keine Lücken und keine Überschneidungen in der Gültigkeit der Versionen",
  customer_sco2pes_co2_database_type_valid: "Alle erforderlichen Daten gegeben",
  customer_sco2pes_co2_database_import_title: "CO<sub>2</sub>-Typen importieren",
  customer_sco2pes_co2_database_import_subtitle: "CO<sub>2</sub>-Typen können über eine Excel-Datei importiert werden. Verwenden Sie diese Excel-Vorlage für den Datei-Upload:",
  customer_sco2pes_co2_database_co2_category: "CO<sub>2</sub> Kategorie",
  customer_sco2pes_co2_database_freetext_search: "Freitext Suche (ID/Name)",
  customer_sco2pes_co2_database_type_active_checkbox: "CO<sub>2</sub>-Typ aktiv",
  customer_sco2pes_co2_database_documentation: "Dokumentation",
  customer_sco2pes_co2_database_external_co2_emissions_factor: "Emissionsdatenbank",
  customer_sco2pes_co2_database_manual_data_entry: "Manuelle Dateneingabe",
  customer_sco2pes_co2_database_scale_factor: "Skalierungsfaktor",
  customer_sco2pes_co2_database_emission_factor_imported: "Emissionsfaktor importiert aus Emissionsdatenbank",
  customer_sco2pes_co2_database_database: "Datenbank",
  customer_sco2pes_co2_database_emission_factor_name: "Name des Emissionsfaktors",
  customer_sco2pes_co2_database_emission_factor_value: "Wert des Emissionsfaktors",
  customer_sco2pes_co2_database_scale_factor_warning: "Stellen Sie sicher, dass ein Skalierungsfaktor konfiguriert ist. Wählen Sie den Wert in Bezug auf die Einheiten.",
  customer_sco2pes_co2_database_scale_factor_description: "Wenn die Einheit des CO<sub>2</sub>-Typs nicht mit der Emissionsdatenbank übereinstimmt, kann der Skalierungsfaktor verwendet werden, um den Emissionsfaktor entsprechend anzupassen.",
  customer_sco2pes_co2_database_change_emission_factor: "Emissionsfaktor ändern",
  customer_sco2pes_co2_database_version_emission_dialog_heading: "CO<sub>2</sub> Emissionsfaktoren",

  customer_sco2pes_co2_type_tags: "CO<sub>2</sub>-Typ-Tags",
  customer_sco2pes_co2_type_tags_subtitle: "CO<sub>2</sub>-Typ-Tags sind eine Möglichkeit, jedem CO<sub>2</sub>-Typ benutzerdefinierte Informationen hinzuzufügen. Es ermöglicht Ihnen, die CO<sub>2</sub> Typen Datenbank mit Ihrem eigenen System zu verbinden/zu integrieren.",
  customer_sco2pes_co2_type_tags_tagging_unavailable: "Tagging ist für Ihr Unternehmen noch nicht verfügbar. Bitte definieren Sie zunächst einige Tags.",
  customer_sco2pes_co2_type_tags_add_tag: "Hinzufügen",
  customer_sco2pes_co2_type_tags_enter_edit_mode: "Bearbeiten",
  customer_sco2pes_co2_type_tags_enter_edit_mode_tooltip: "Ihre Änderungen werden automatisch gespeichert, wenn Sie mit der Eingabe im Textfeld beginnen.",
  customer_sco2pes_co2_type_tags_exit_edit_mode: "Verlassen",
  customer_sco2pes_co2_type_tags_edit_mode_info: "Ihre Änderungen werden automatisch gespeichert, während Sie im Tabellenfeld tippen.",
  customer_sco2pes_co2_type_tags_deletion_confirmation: "Sind Sie sicher, dass Sie dieses CO<sub>2</sub>-Typ-Tag dauerhaft löschen möchten?",
  customer_sco2pes_co2_type_tags_edit_tag: "Tag bearbeiten",
  customer_sco2pes_co2_type_tags_search: "Suche (ID, Name, Tag)",

  customer_sco2pes_configuration_information: "Information",
  customer_sco2pes_configuration_contact_support: "Wenn keine Daten angezeigt werden, kontaktieren Sie bitte das Support-Team.",
  customer_sco2pes_configuration_tag_definitions: "Tags",
  customer_sco2pes_configuration_composite_keys: "Composite Keys",
  customer_sco2pes_configuration_selection_policies: "Auswahlrichtlinien",
  customer_sco2pes_configuration_column_index: "Spaltenindex",
  customer_sco2pes_configuration_unique: "Einzigartig",
  customer_sco2pes_configuration_key_chain: "Key Chain",
  customer_sco2pes_configuration_key_type: "Key Type",
  customer_sco2pes_configuration_key_name: "Key Name",
  customer_sco2pes_configuration_required_tag_defs: "Erforderliche Tag-Definitionen",
  customer_sco2pes_configuration_tags: "Tag",
  customer_sco2pes_configuration_json_key: "JSON key",
  customer_sco2pes_configuration_in_use_in_automation_api: "in Verwendung in der Automation-API",
  customer_sco2pes_configuration_esrs_mapping: "ESRS Mapping",
  customer_sco2pes_configuration_esrs_mapping_description_1: "Mittels ESRS-Mapping können Buchungen aus dem CCF mit ESRS Kennzahlen für die CSRD angereichert werden, welche über die Automation API V5 verfügbar gemacht werden.",
  customer_sco2pes_configuration_esrs_mapping_description_2: "Für jede ESRS Kennzahl können ein oder mehrere Scopes, CO<sub>2</sub> Kategorien und/oder CO<sub>2</sub> Typen konfiguriert werden. Alle Buchungen, die mit diesen Konfigurationen übereinstimmen, werden mit der entsprechenden ESRS Kennzahl angereichert.",
  customer_sco2pes_configuration_esrs_mapping_description_3: "Die Mapping Konfiguration kann als Excel-Datei heruntergeladen und bearbeitet werden. Nach der Bearbeitung kann die Datei wieder hochgeladen werden. Beim Hochladen werden die Spalten validiert und die Konfiguration wird gespeichert. Im Falle von Validierungsfehlern wird die Konfiguration nicht gespeichert, es bleibt die alte Konfiguration bestehen.",
  customer_sco2pes_configuration_esrs_mapping_description_4: "Bitte beachten: Zur besseren Lesbarkeit wird die heruntergeladene Excel-Datei mit weiteren Spalten angereichert. Diese Spalten sind für die Konfiguration nicht relevant und können beim Upload ignoriert werden.",
  customer_sco2pes_configuration_esrs_mapping_download: "ESRS Mapping Konfiguration Herunterladen",
  customer_sco2pes_configuration_esrs_mapping_upload: "ESRS Mapping Konfiguration Hochladen",
  customer_sco2pes_configuration_esrs_mapping_subtitle: "Die ESRS Mapping Konfiguration kann über eine Excel-Datei hochgeladen werden. Verwenden Sie diese Excel-Vorlage für den Daten-Upload:",

  //External Databases
  customer_sco2pes_co2_database_own_emission_factors: "Eigene CO<sub>2</sub> Typen",
  customer_sco2pes_co2_database_global_emission_factors: "Verwaltete CO<sub>2</sub> Typen",
  customer_sco2pes_co2_database_external_databases: "Emissionsdatenbanken",
  customer_sco2pes_co2_database_external_databases_search: "Freitext-Suche",
  customer_sco2pes_co2_database_database_label: "Datenbank",
  customer_sco2pes_co2_database_valid_from: "Gültig Ab",
  customer_sco2pes_co2_database_valid_until: "Gültig Bis",
  customer_sco2pes_co2_database_name_description: "Name/Beschreibung",
  customer_sco2pes_co2_database_valid_period: "Gültig (von - bis)",
  customer_sco2pes_co2_database_geography: "Geographie",
  customer_sco2pes_co2_database_actions: "Aktionen",
  customer_sco2pes_co2_database_details: "Einzelheiten",
  customer_sco2pes_co2_database_delete: "Löschen",

  // Bookings
  customer_sco2pes_booking_successfully_canceled: "Buchung erfolgreich storniert.",
  customer_sco2pes_cancel_bookings: "Buchungen stornieren",
  customer_sco2pes_confirm_canceling_selected_bookings: "Möchten Sie wirklich die {selectedBookingsCount} ausgewählten Buchung(en) stornieren?",
  customer_sco2pes_bookings_successfully_canceled: "{selectedBookingCount} Buchung(en) erfolgreich storniert.",

  customer_sco2pes_pending_unit_bookings: "Ausstehende Unit-Buchungen",
  customer_sco2pes_pending_unit_bookings_details: "Details zu ausstehenden Unit-Buchungen",
  customer_sco2pes_pending_financial_bookings: "Ausstehende Financials Buchungen",
  customer_sco2pes_pending_financial_bookings_details: "Details zu ausstehenden Financials Buchungen",
  customer_sco2pes_pending_unit_bookings_search_fields: "Sie können in den folgenden Feldern suchen:<br/><ul><li>Tag-Wert</li><li>Buchungsnummer</li><li>Unternehmenscode Name und Code</li><li>CO<sub>2</sub>-Typ-Name</li><li>CO<sub>2</sub>-Kategorie-Name</li><li>Lieferantenname</li><li>Kreditoren-ID</li></ul>",
  customer_sco2pes_pending_financial_bookings_search_fields: "Sie können in den folgenden Feldern suchen:<br/><ul><li>Herkunft</li><li>Unternehmenscode Name und Code</li><li>Finanzmodellname</li><li>Finanzmodellkontonummer und interne Auftragsnummer</li><li>Lieferantenname</li><li>Kreditoren-ID</li></ul>",
  customer_sco2pes_pending_recalc_alert_title: "Aktivieren Sie die ausstehenden Buchungen",
  customer_sco2pes_pending_recalc_alert_subtitle: "Klicken Sie auf diese Schaltfläche, um die ausstehenden Buchungen zu aktivieren. Der Vorgang kann einige Minuten dauern.",
  customer_sco2pes_pending_no_effect_on_locked_booking_period: "Auf gesperrte Buchungszeiträume hat die Aktivierung keinen Einfluss.",
  customer_sco2pes_pending_recalc_alert_button: "Buchungen aktivieren",
  customer_sco2pes_pending_recalc_alert_dialog_title_unit: "Aktivieren Sie die ausstehenden Unit-Buchungen",
  customer_sco2pes_pending_recalc_alert_dialog_body_unit: "Durch Klicken auf die Schaltfläche „Weiter“ versucht das System, alle ausstehenden Unit-Buchungen zu aktivieren. Dieser Vorgang kann nicht rückgängig gemacht werden.",
  customer_sco2pes_pending_recalc_alert_dialog_title_financial: "Aktivieren Sie die ausstehenden Finanzbuchungen",
  customer_sco2pes_pending_recalc_alert_dialog_body_financial: "Durch Klicken auf die Schaltfläche „Weiter“ versucht das System, alle ausstehenden Finanzbuchungen zu aktivieren. Dieser Vorgang kann nicht rückgängig gemacht werden.",
  customer_sco2pes_pending_recalc_alert_info_dialog_button_unit: "Unit-Buchungen",
  customer_sco2pes_pending_recalc_alert_info_dialog_button_financial: "Finanzbuchungen",

  // Unit bookings
  customer_sco2pes_unit_bookings_unit_bookings: "Unit Buchungen",
  customer_sco2pes_unit_bookings_subtitle: "In diesem Bereich können Sie entsprechend Buchungen auf Basis von Mengeneinheiten (zum Beispiel: kWh, kg, l, ...) vornehmen. Hierzu können Sie über die „Upload-Funktion“, die bereitgestellte Excel-Tabelle exportieren, befüllen und in der Software hochladen. Alternativ können Sie über „+ UNIT BUCHUNG“ manuell die gewünschten Buchungsbeträge eingeben.",
  customer_sco2pes_unit_bookings_unit_booking: "Unit Buchung",
  customer_sco2pes_financial_bookings_description: "Bitte geben Sie den Gesamtbetrag ein, welcher im betrachteten Zeitraum entsprechend Ihrer Summen- und Saldenliste/ Gewinn- und Verlustrechnung auf dem Konto <b>{0}</b> (Kontonummer: <b>{1} / {2}</b>) verbucht wurde.",
  customer_sco2pes_financial_bookings_description_2: "Die Buchungs-ID, die zur eindeutigen Identifizierung der Buchung verwendet wird.<br /> Es wird empfohlen, z. B. eine Rechnungsnummer zu verwenden.",
  customer_sco2pes_financial_bookings_id: "Buchungs-ID",
  customer_sco2pes_financial_bookings_accounting_period: "Abrechnungszeitraum",
  customer_sco2pes_unit_bookings_creation_dialog_title: "Unit Buchung erstellen",
  customer_sco2pes_unit_bookings_all_categories: "Alle Kategorien",
  customer_sco2pes_unit_bookings_select_a_category: "Bitte wählen Sie eine Kategorie",
  customer_sco2pes_unit_bookings_amount: "Menge",
  customer_sco2pes_unit_bookings_cancel_booking_question: "Soll die Buchung wirklich storniert werden?",
  customer_sco2pes_unit_bookings_show_only_my_bookings: "Nur meine Buchungen zeigen",
  customer_sco2pes_unit_bookings_order_id: "Buchungsnummer",
  customer_sco2pes_unit_bookings_type: "Typ",
  customer_sco2pes_unit_bookings_supplier: "Lieferanten",
  customer_sco2pes_unit_bookings_category: "Kategorie",
  customer_sco2pes_unit_bookings_unit_booking_details: "Buchungsdetails",
  customer_sco2pes_unit_bookings_cancel_booking: "Buchung stornieren",
  customer_sco2pes_unit_bookings_details: "Detaills",
  customer_sco2pes_unit_bookings_not_set: "Nicht gesetzt",
  customer_sco2pes_unit_bookings_meta_data: "Meta Daten",
  customer_sco2pes_unit_bookings_co2_values: "CO<sub>2</sub> Werte",
  customer_sco2pes_unit_bookings_all: "Alle",
  customer_sco2pes_unit_bookings_created_at: "Erstellt am",
  customer_sco2pes_unit_bookings_financial_bookings: "Financials Buchungen",
  customer_sco2pes_unit_bookings_co2_emissions_in_kg: "Emissionen in kg CO<sub>2</sub>e",
  customer_sco2pes_unit_bookings_financial_booking: "Financials Buchung",
  customer_sco2pes_unit_bookings_financial_booking_details: "Financials Buchungsdetails",
  customer_sco2pes_unit_bookings_model_description: "Modell Beschreibung",
  customer_sco2pes_unit_bookings_cancel_booking_warning: "Diese Buchung ist Teil der Financials Buchung {0}.<br> Sie kann in den Financials Buchungsdetails storniert werden.",
  customer_sco2pes_unit_bookings_co2_category: "CO<sub>2</sub> Kategorie",
  customer_sco2pes_unit_bookings_booking_creation_success_title: "CO<sub>2</sub> Buchung erfolgreich hinzugefügt!",
  customer_sco2pes_unit_bookings_booking_creation_success_next_booking: "Nächste Buchung",
  customer_sco2pes_unit_bookings_booking_creation_success_show_bookings: "Buchungen anzeigen",
  customer_sco2pes_unit_bookings_co2_type: "CO<sub>2</sub> Typ",
  customer_sco2pes_unit_bookings_auto_generated: "Automatisch generiert",
  customer_sco2pes_unit_bookings_auto_generated_by_financial_booking: "Duch Finanzbuchung",
  customer_sco2pes_unit_bookings_auto_generated_by_commuter_survey: "Durch Pendlerumfrage",
  customer_sco2pes_unit_bookings_advance_search: "Erweiterte Suche",
  customer_sco2pes_unit_bookings_distance_multiply_description: "Die Gesamtdistanz ist das Product von Menge und Distanz",
  customer_sco2pes_unit_bookings_copy_co2_type: "CO<sub>2</sub> Typ ID kopieren",
  customer_sco2pes_unit_bookings_fill_the_details: "Details ausfüllen",
  customer_sco2pes_unit_bookings_select_co2_type_desc: "Wählen Sie den CO<sub>2</sub> Typ, für den Sie die Buchung erstellen möchten, und danach fahren Sie fort, die Buchungsdetails auszufüllen",
  customer_sco2pes_unit_bookings_cancel_creating_booking: "Erstellung der Buchung abbrechen",
  customer_sco2pes_unit_bookings_cancel_creating_booking_question: "Möchten Sie wirklich das Erstellen der Buchung abbrechen?",
  customer_sco2pes_unit_bookings_select_an_order_period: "Wählen Sie einen Buchungszeitraum aus",
  customer_sco2pes_unit_bookings_create: "Übermitteln",
  customer_sco2pes_unit_bookings_clone_dialog_title: "Unit Buchungen Klonen",
  customer_sco2pes_unit_bookings_clone_dialog_order_id_prefix: "Buchungsnummer-Präfix",
  customer_sco2pes_unit_bookings_clone_dialog_order_id_prefix_desc: `Die Buchungsnummer wird nicht geklont! Stattdessen generieren wir neue für Sie. Alle geklonten Buchungen beginnen mit diesem Buchungsnummer-Präfix. Zum Beispiel, wenn Sie "clone-" eingeben, dann wird die Buchungsnummer der geklonten Buchungen sein: <b>clone-01, clone-02, ..., clone-124</b>`,
  customer_sco2pes_unit_bookings_clone_dialog_increase_order_period: "Buchungszeitraum verlängern",
  customer_sco2pes_unit_bookings_clone_dialog_increase_order_period_desc: `
  Wenn Sie die Buchungen in der Zukunft klonen möchten, geben Sie einen Wert größer oder gleich 0 ein. Dies ist die Anzahl
  von Monaten, die wir zum Buchungszeitraum jeder Buchung hinzufügen.
  `,
  customer_sco2pes_unit_bookings_clone_dialog_count: "Unit Buchungen gefunden entsprechend der angewendeten Filterung",
  customer_sco2pes_unit_bookings_clone_dialog_confirmation: "Möchten Sie {count} Unit Buchungen klonen?",
  customer_sco2pes_unit_bookings_clone_dialog_success: "{count} Unit Buchungen erfolgreich geklont",
  customer_sco2pes_unit_bookings_created_after: "Erstellt nach",
  customer_sco2pes_unit_bookings_created_before: "Erstellt vor",

  // Unit bookings upload
  customer_sco2pes_unit_bookings_import: "Unit Buchungen importieren",
  customer_sco2pes_unit_bookings_tag_description: "Tagging Spalten Namen - nur benötigt, wenn Tagging Spalten im Upload verwendet werden:",
  customer_sco2pes_unit_bookings_tag_tooltip: "Die Nutzung des Taggings innerhalb der Mengenbuchungen ermöglicht es Ihnen Buchungen zusätzliche Informationen hinzuzufügen. Diese Informationen haben keine Auswirkungen auf die Berechnung der Emissionen. Die Nutzung von Tags ist nicht verplichtend notwendig um Emissionen innerhalb des CCF-Tools zu berechnen.",
  customer_sco2pes_unit_bookings_tag_1: "Tag 1",
  customer_sco2pes_unit_bookings_tag_2: "Tag 2",
  customer_sco2pes_unit_bookings_tag_3: "Tag 3",
  customer_sco2pes_unit_bookings_limitations: "Limitationen",
  customer_sco2pes_unit_bookings_files_maximum_size: "Datei dürfen nicht größer als 5 MB sein.",
  customer_sco2pes_unit_bookings_required_file_type: "Datei muss vom Typ xlsx (Excel) sein.",
  customer_sco2pes_unit_bookings_required_sheet_details: "Datei darf nur ein Blatt haben.",
  customer_sco2pes_unit_bookings_upload_can_not_be_cancelled: "Upload-Vorgang kann nicht abgebrochen werden",
  customer_sco2pes_unit_bookings_upload_file_multiple_times: "Sie können dieselbe Datei mehrmals hochladen, bereits vorhandene Buchungen werden ignoriert.",
  customer_sco2pes_unit_bookings_upload_file_not_processed: "Die Datei konnte nicht verarbeitet werden. Folgende(r) Fehler ist/sind aufgetreten",
  customer_sco2pes_unit_bookings_upload_output_file_name: "unit_buchungen_upload_ergebnisse",
  customer_sco2pes_unit_bookings_upload_output_sheet_name: "Ergebnisse",
  customer_sco2pes_unit_bookings_import_subtitle: "Unit Buchungen können über eine Excel-Datei importiert werden. Verwenden Sie diese Excel-Vorlage für den Datei-Upload:",

  // Financial bookings
  customer_sco2pes_financial_bookings_financial_booking: "Financial Buchung",
  customer_sco2pes_financial_bookings_subtitle: "In diesem Bereich können Sie entsprechend Ihrer Finanzmodelle Buchungen auf Basis von Finanzdaten vornehmen. Hierzu können Sie über „+ FINANCIAL BUCHUNG“ manuell die gewünschten Buchungsbeträge entsprechend Ihrer Summen- und Saldenliste eingeben.",
  customer_sco2pes_financial_bookings_creation_dialog_title: "Financial Buchung erstellen",
  customer_sco2pes_financial_bookings_amount: "Betrag",
  customer_sco2pes_financial_bookings_detailed: "Detailliert",
  customer_sco2pes_financial_bookings_form_description: "Bitte geben Sie die Ausgaben für die verschiedenen Kategorien in dem von Ihnen angegebenen Abrechnungszeitraum ein, <strong>ohne Umsatzsteuer</strong>.",
  customer_sco2pes_financial_bookings_general_information: "Allgemeine Informationen",
  customer_sco2pes_financial_bookings_relevant_information: "relevante Informationen",
  customer_sco2pes_financial_bookings_creation_success_title: "Buchung erfolgreich erstellt.",
  customer_sco2pes_financial_bookings_creation_success_next_booking: "Nächste Buchung",
  customer_sco2pes_financial_bookings_creation_success_show_bookings: "Buchungen anzeigen",
  customer_sco2pes_financial_bookings_search_by_origin: "Suche nach Quelle",
  customer_sco2pes_financial_bookings_cancel_booking: "Buchung stornieren",
  customer_sco2pes_financial_bookings_cancel_booking_question: "Soll die Buchung wirklich storniert werden?",
  customer_sco2pes_financial_bookings_booking_successfully_canceled: "Buchung erfolgreich storniert.",
  customer_sco2pes_financial_bookings_duplicate_tooltip: "Duplizieren",
  customer_sco2pes_financial_bookings_import_title: "Finanzbuchungen importieren",
  customer_sco2pes_financial_bookings_import_subtitle: "Finanzbuchungen können über eine Excel-Datei importiert werden. Verwenden Sie diese Excel-Vorlage für den Datei-Upload:",
  customer_sco2pes_financial_bookings_company_code_not_found: "Buchungskreis nicht gefunden!",
  customer_sco2pes_financial_bookings_create_company_code_warning: "Bitte legen Sie zunächst einen Buchungskreis an, um Finanzbuchungen freizuschalten.",
  customer_sco2pes_financial_bookings_create_financial_model_warning: "Bitte erstellen Sie zunächst ein <a href='{link}'>Finanzmodell</a>, um Finanzbuchungen hinzufügen zu können",

  // Checklist
  customer_sco2pes_checklist_title: "Checkliste",
  customer_sco2pes_handbook_title: "Handbuch",
  customer_sco2pes_handbook_description: "Hier können Sie eine detaillierte Anleitung herunterladen, um die nahtlose integration und Berechnung Ihres betrieblichen CO<sub>2</sub>-Fußabdrucks durchzuführen. Dieses Handbuch beinhaltet einen Leitfaden, sowie Hinweise um die Arbeit und Erstellung Ihres nächsten CO<sub>2</sub>-Fußabdrucks innerhalb der Softwareumgebung zu vereinfachen.",
  customer_sco2pes_checklist_information_description: "Diese Checkliste dient als Hilfestellung für den Carbon Footprint des Unternehmens für das Berichtsjahr. Die folgenden Schritte erleichtern den Übergang zur eigenständigen Nutzung der Software, insbesondere für Unternehmen ab dem zweiten Berichtsjahr.",
  customer_sco2pes_checklist_more_info: "Mehr Informationen: Kapitel {chapterNumber} Handbuch",
  customer_sco2pes_checklist_base_data: "Stammdaten",
  customer_sco2pes_checklist_bookings: "Buchungen",
  customer_sco2pes_checklist_commuter_survey: "Pendlerumfrage",
  customer_sco2pes_checklist_reporting: "Berichterstellung",

  // Suppliers
  customer_sco2pes_suppliers_transport_routes: "Transportwege",
  customer_sco2pes_suppliers_supplier: "Lieferant",
  customer_sco2pes_suppliers_suppliers: "Lieferanten",
  customer_sco2pes_suppliers_subtitle: "Im Kontext der CO<sub>2</sub>-Emissionen spielt die Analyse von Lieferketten eine wichtige Rolle. Um die CO<sub>2</sub>-Emissionen eines Unternehmens vollständig zu erfassen, müssen daher die Emissionen entstehend durch Transport von den Lieferanten in die Berechnungen einbezogen werden. Im Folgenden werden mit Hilfe Ihrer Lieferantendaten Transportwege erstellt, um die Emissionen entlang Ihrer vor- und nachgelagerten Lieferkette zu betrachten.<br /><br />Für die Berechnung der Emissionen aus dem Transport von Produkten / Materialien / Rohstoffen laden Sie bitte Ihre Lieferantendaten entsprechend der Vorlage hoch, welche in der Importfunktion hinterlegt ist. Um Ihnen die Angabe der Entfernung zu erleichtern, wird diese automatisiert berechnet.<br /> Alternativ können Sie auch einzeln Ihre Lieferanten im System erfassen. Bitte nutzen Sie hierzu den Button „+ LIEFERANT“.",
  customer_sco2pes_suppliers_supplier_details: "Lieferanten Details",
  customer_sco2pes_suppliers_transport_type: "Transportart",
  customer_sco2pes_suppliers_distance: "Entfernung",
  customer_sco2pes_suppliers_transport_route_segment: "Transportweg-Segment",
  customer_sco2pes_suppliers_transport_route: "Transportweg",
  customer_sco2pes_suppliers_transport_route_description: "Transportwege werden zur Berechnung von Transportemissionen verwendet. Eine Transportweg kann aus mehreren Segmenten mit unterschiedlichen Transportarten bestehen. Beispiel: Ein Produkt kann eine bestimmte Strecke von Ort A per Schiff nach Ort B und von dort per LKW nach Ort C transportiert werden.",
  customer_sco2pes_transport_route_documentation: "Dokumentation / Notizen",
  customer_sco2pes_suppliers_transport_route_segment_deactivated_type: "Dieser CO2-Typ ist deaktiviert. Für neue Buchungen sollte ein anderer CO2-Typ verwendet werden.",
  customer_sco2pes_suppliers_transport_route_successfully_created: "Transportweg erfolgreich erstellt",
  customer_sco2pes_suppliers_transport_route_successfully_edited: "Transportweg erfolgreich bearbeitet",
  customer_sco2pes_suppliers_transport_route_successfully_deleted: "Transportweg erfolgreich gelöscht",
  customer_sco2pes_suppliers_creditor_number: "Kreditoren Nummer",
  customer_sco2pes_suppliers_new_supplier: "Lieferant erstellen",
  customer_sco2pes_suppliers_supplier_successfully_created: "Lieferant erfolgreich erstellt",
  customer_sco2pes_suppliers_supplier_successfully_edited: "Lieferant erfolgreich bearbeitet",
  customer_sco2pes_suppliers_supplier_successfully_deleted: "Lieferant erfolgreich gelöscht",
  customer_sco2pes_suppliers_do_you_really_want_to_delete_the_supplier: "Möchten Sie den Lieferanten wirklich löschen",
  customer_sco2pes_suppliers_edit_supplier: "Lieferant bearbeiten",
  customer_sco2pes_suppliers_all: "Alle",
  customer_sco2pes_suppliers_add_address: "Adresse hinzufügen",
  customer_sco2pes_suppliers_remove_address: "Adresse entfernen",
  customer_sco2pes_suppliers_data_completed: "Daten vollständig",
  customer_sco2pes_suppliers_data_uncompleted: "Daten unvollständig",
  customer_sco2pes_suppliers_uncompleted_warning: "Der Lieferant wurde automatisch mit einer CO<sub>2</sub> Buchung erstellt. Bitte vervollständigen Sie die Informationen.",
  customer_sco2pes_suppliers_edit: "Editieren",
  customer_sco2pes_suppliers_selection_rule: "Selection Rule",
  customer_sco2pes_suppliers_new_selection_rule: "Selection Rule erstellen",
  customer_sco2pes_suppliers_default_transport_route_for: "Standard-Transportweg für {0}",
  customer_sco2pes_transport_route_version_validity: "Gültigkeit",
  customer_sco2pes_transport_route_version: "Version",
  customer_sco2pes_transport_route_versions: "Versionen",
  customer_sco2pes_transport_route_versions_add_version: "Version hinzufügen",
  customer_sco2pes_transport_route_delete_version: "Version löschen",
  customer_sco2pes_transport_route_distance: "Distanz",
  customer_sco2pes_transport_route_start_address_not_completed: "Start Adresse Daten nicht vollständig",
  customer_sco2pes_transport_route_destination_address_not_completed: "Ziel Adresse Daten nicht vollständig",
  customer_transport_route_validation_1: "Der Name und der Typ des Transportwegs sind angegeben",
  customer_transport_route_validation_3: "Erforderliche Daten für alle Versionen angegeben (Land, Stadt für alle Adressen erforderlich, Verkehrsmittel und Entfernung zwischen allen Adressen erforderlich)",
  customer_transport_route_version_validation_1: "<i>From</i> und <i>Until</i> müssen gesetzt sein",
  customer_transport_route_version_validation_2: "<i>Bis</i> muss nach <i>Von</i> sein",
  customer_transport_route_add_transit: "Transit hinzufügen",
  customer_transport_route_auto_calculate_distance: "Entfernung berechnen",
  customer_transport_route_auto_calculate_distance_tooltip: "Eine automatische Berechnung ist nur möglich wenn <i>Land</i> und <i>Stadt</i> von Start und Ziel und Transport <i>Kategory</i> und <i>Typ</i> gesetzt sind.",
  customer_transport_route_number_of_versions: "Anzahl der Versionen",
  customer_transport_route_versions: "Versionen",
  customer_transport_route_initial_version: "Erstversion",
  customer_transport_route_set_default: "Als Standard setzen",
  customer_transport_route_unset_default: "Als Standard aufheben",
  customer_transport_route_distance_could_not_be_calculated_title: "Distanz konnte nicht berechnet werden",
  customer_transport_route_distance_could_not_be_calculated_desc: "Die Distanz konnte nicht berechnet werden. Es sind die folgenden Fehler aufgetreten:",
  customer_sco2pes_suppliers_import_title: "Lieferanten importieren",
  customer_sco2pes_suppliers_import_subtitle: "Lieferanten können über eine Exceldatei importiert werden. Verwenden Sie diese Excel-Vorlage für den Datei-Upload:",
  customer_sco2pes_suppliers_co2_relevant_description: "Wenn ein Lieferant als nicht CO2-relevant markiert ist, werden nur die Emissionen des Transports berücksichtigt, die Emissionen für die gelieferten Materialien werden jedoch vernachlässigt. Diese Konfiguration ist sinnvoll, wenn es sich bei dem Lieferanten um eine Tochtergesellschaft Ihres Unternehmens handelt und wenn der Lieferant bereits die Materialemissionen berücksichtigt.",
  customer_sco2pes_suppliers_upload_output_file_name: "lieferanten_upload_ergebnisse",
  customer_sco2pes_transport_route_reset_to_default_name: "Auf Standardname zurücksetzen",

  // Financial models
  customer_sco2pes_financial_model: "Finanzmodell",
  customer_sco2pes_financial_models: "Finanzmodelle",
  customer_sco2pes_financial_models_subtitle: "Um Ihre finanzdatenbasierte CO<sub>2</sub>-Bilanz erstellen zu können, müssen Sie in diesem Schritt Ihre CO<sub>2</sub>-relevanten Finanzdaten eingeben. Hierfür benötigen Sie Informationen aus Ihrer Buchhaltung und Ihrem Rechnungswesen.<br/>Unser Ansatz basiert auf einem mathematischen Modell zur Berechnung der Emissionen der zu berücksichtigen Geschäftsvorfälle auf Grundlage der Finanzbuchhaltung Ihres Unternehmens. Zur Generierung der mathematischen Modelle (Finanzmodell) werden in diesem Schritt Ihre CO<sub>2</sub>-relevanten Konten und die darauf verbuchten Geschäftsvorfälle identifiziert.<br/><br/>Sie können Finanzmodelle über den Button „+ FINANZMODELL“ für einzelne Konten angelegen.<br/>Bitte beachten Sie, dass für jedes Konto ein eigenes Finanzmodell angelegt werden muss und die Bearbeitung aller Konten / Geschäftsvorfälle notwendig ist, um diesen Schritt abzuschließen. Nähere Informationen hierzu finden Sie in den jeweiligen Detailansichten.",
  customer_sco2pes_financial_model_model_info: "Modell Info",
  customer_sco2pes_financial_model_account_info: "Konto Info",
  customer_sco2pes_financial_model_account: "Konto",
  customer_sco2pes_financial_model_supplier: "Lieferant",
  customer_sco2pes_financial_model_transport_route: "Transportweg",
  customer_sco2pes_financial_models_suppliers_or_transport_routes: "Lieferanten/Transportwege",
  customer_sco2pes_financial_model_percentage: "Prozent",
  customer_sco2pes_financial_model_co2_type_price: "CO<sub>2</sub> Typ Preis (Preis pro Einheit)",
  customer_sco2pes_financial_model_price_per_unit: "Preis pro Einheit",
  customer_sco2pes_financial_account_number: "Kontonummer",
  customer_sco2pes_financial_internal_order_number: "Interne Auftragsnummer",
  customer_sco2pes_financial_model_versions: "Versionen",
  customer_sco2pes_financial_model_version_hint: "Für ein Finanzmodell können die Emissionsarten im Laufe der Zeit versioniert werden. Versionen können in der zeitlichen Auflösung von Monaten erstellt werden. Versionen müssen alle möglichen Monate abdecken, beginnend mit \"Start\" bis \"Ende\".",
  customer_sco2pes_financial_emission_type: "Emissionsarten",
  customer_sco2pes_financial_validations: "Validierungen",
  customer_sco2pes_financial_validation_1: "Buchungskreis, Name (en & de) und Währung sind festgelegt",
  customer_sco2pes_financial_validation_2: "Es gibt keine Lücken oder Überschneidungen bei den Zeiträumen der Versionen",
  customer_sco2pes_financial_validation_3: "Jede Version hat mindestens einen Emissions-Typen",
  customer_sco2pes_financial_validation_4: "Jeder Emissions-Typ is vollständig (positive Prozente, positiver Preis, ein CO<sub>2</sub> Typ ist ausgewählt)",
  customer_sco2pes_financial_edit_warning: "Buchungen welche mit dieser Konfiguration bereits erstellt wurden, werden neu berechnet und entsprechend der neuen Konfiguration aktualisiert.",
  customer_sco2pes_financial_edit__locking_warning: "Die Aktualisierung eines Finanzmodells hat keine Auswirkungen auf gesperrte Buchungen und abgeschlossene Berichte.",
  customer_sco2pes_financial_model_total_percentage_over_100: "Die Gesamtprozentzahl übersteigt 100%",
  customer_sco2pes_financial_model_co2_type_weightless: "Der ausgewählte CO<sub>2</sub>-Type hat kein Gewicht, daher würde die Auswahl eines Lieferanten Auswirkungen auf die CO<sub>2</sub>-Emissionsberechnungen haben.",

  // CO2 Type selection
  customer_sco2pes_co2_type_selection_deactivated: "Deaktivierter CO<sub>2</sub> Typ",
  customer_sco2pes_co2_type_selection_active: "Aktiver CO<sub>2</sub> Typ",
  customer_sco2pes_co2_type_selection_open: "CO<sub>2</sub> Typ Auswahl öffnen",
  customer_sco2pes_co2_type_selection: "CO<sub>2</sub> Typ Auswahl",

  // Company codes
  customer_sco2pes_company_codes_company_codes: "Buchungskreise",
  customer_sco2pes_company_codes_subtitle: "Buchungskreise sind organisatorische Einheiten für die eine in sich geschlossene Buchhaltung erstellt werden kann. Einheiten können beispielsweise sein: Standorte, Werke, Kostenstellen. Daraus kann abgeleitet werden, welche Daten künftig im Dashboard auswertbar sind. Somit haben Sie die Möglichkeit, Ergebnisse zu monitoren und zielgerichtete Maßnahmen zu entwickeln. Jeder Buchungskreis wird durch einen eindeutigen Buchungscode identifiziert. Der Buchungscode kann entweder aus Ihren Buchhaltungssystemen (SAP o.ä.) übernommen oder manuell vergeben werden. Dies kann zum Beispiel über eine fortlaufende Nummierung erfolgen.<br/><br/>Beispiel Buchungskreis:<br/>Name: Zentrale / Code: 01",
  customer_sco2pes_company_codes_create_company_code: "Buchungskreis erstellen",
  customer_sco2pes_company_codes_successfully_created: "Buchungskreis erfolgreich erstellt",
  customer_sco2pes_company_codes_started_at: "Gestartet am",
  customer_sco2pes_company_codes_description: "Beschreibung",
  customer_sco2pes_company_codes_configuration: "Konfiguration",
  customer_sco2pes_company_codes_type: "Typ",
  customer_sco2pes_company_codes_configurations: "Konfigurationen",
  customer_sco2pes_company_codes_import_title: "Buchungskreise importieren",
  customer_sco2pes_company_codes_import_subtitle: "Buchungskreise können über eine Excel-Datei importiert werden. Verwenden Sie diese Excel-Vorlage für den Datei-Upload:",

  // Commuter surveys
  customer_sco2pes_commuter_surveys_commuter_surveys: "Pendlerumfragen",
  customer_sco2pes_commuter_surveys_commuter_survey: "Pendlerumfrage Details",
  customer_sco2pes_commuter_surveys_surveys: "Pendlerumfragen",
  customer_sco2pes_commuter_surveys_survey: "Pendlerumfrage",
  customer_sco2pes_commuter_surveys_survey_gender_neutral: "Pendler:innen-Umfrage",
  customer_sco2pes_commuter_surveys_created_at: "Erstellt am",
  customer_sco2pes_commuter_surveys_year: "Jahr",
  customer_sco2pes_commuter_surveys_number_of_answers: "Anzahl der Antworten",
  customer_sco2pes_commuter_surveys_open: "Laufend",
  customer_sco2pes_commuter_surveys_cancelled: "Abgebrochen",
  customer_sco2pes_commuter_surveys_finalized: "Beendet",
  customer_sco2pes_commuter_surveys_max_distance_filter: "Maximale Distanz Filter",
  customer_sco2pes_commuter_surveys_overview_table_description: "Einmal jährlich können Sie mit eine Mitarbeiterumfrage die CO<sub>2</sub> Emissionen der Mitarbeiter Ihres Unternehmens welche durch das Pendeln zum Unternehmensstandord erzeugt werden (Scope 3.7) erfassen. Nach dem Starten einer Umfrage erhalten Sie einen Link, welchen Sie in Ihrem Unternehmen teilen können. Alle Antworten der Mitarbeiter werden anonymisiert gespeichert.<br>Den Fortschritt Ihrer Umfrage können Sie jederzeit in den Umfragedetails einsehen. Bei genügend vielen Antworten können Sie die Umfrage beenden. Die Ergebnisse werden dann exptrapoliert und die daraus resultierenden CO<sub>2</sub> Emissionen werden automatisch dem Buchungskreis hinzugeordnet, für den Sie die Umfrage gestartet haben.",
  customer_sco2pes_commuter_surveys_start_dialog_description: "Nach dem Starten der Umfrage wird ein Link erzeugt, welchen Sie mit den Mitarbeitern Ihres Unternehmens teilen können.",
  customer_sco2pes_commuter_surveys_included_transportation_types_description: "Bestimmen Sie selbst, welche Transporttypen Sie in der Umfrage inkludieren wollen.<br>Stellt ihr Unternehmen beispielsweise einen Shuttelbus für die Mitarbeiter zur Verfügung, so können Sie dafür einen eigenen CO<sub>2</sub> Typen für Scope 3.7 in der CO<sub>2</sub> Datenbank anlegen. Dieser steht ihnen dann hier ebenfalls beim Starten einer Umfrage zur Verfügung. Standardmäßig sind alle Scope 3.7 CO<sub>2</sub> Typen für die Umfrage vorausgewählt.",
  customer_sco2pes_commuter_surveys_co2_type: "CO<sub>2</sub>-Typ",
  customer_sco2pes_commuter_surveys_warnings: "Warnungen",
  customer_sco2pes_commuter_surveys_filter_answers_with_warning: "Filter Antworten mit Warnungen",
  customer_sco2pes_commuter_surveys_answer: "Antwort",
  customer_sco2pes_commuter_surveys_answers: "Antworten",
  customer_sco2pes_commuter_surveys_co2_emissions_in_kg: "Emissionen in kg CO<sub>2</sub>e",
  customer_sco2pes_commuter_surveys_exclude: "exkludieren",
  customer_sco2pes_commuter_surveys_include: "inkludieren",
  customer_sco2pes_commuter_surveys_answer_warning: "Antwort überschreitet die maximale Entfernung",
  customer_sco2pes_commuter_surveys_add_answer_to_survey: "Antwort zur Umfrage hinzufügen",
  customer_sco2pes_commuter_surveys_distance: "Entfernung",
  customer_sco2pes_commuter_surveys_cancel: "Umfrage abbrechen",
  customer_sco2pes_commuter_surveys_finalize: "Umfrage Beenden",
  customer_sco2pes_commuter_surveys_cancel_commuter_survey: "Pendlerumfrage abbrechen",
  customer_sco2pes_commuter_surveys_finalize_commuter_survey: "Pendlerumfrage beenden",
  customer_sco2pes_commuter_surveys_cancel_commuter_survey_confirmation: "Möchten Sie die Umfrage wirklich abbrechen?<br> Mitarbeiter können die Umfrage nicht mehr beantworten. Es werden keine CO<sub>2</sub>-Emissionen berechnet.",
  customer_sco2pes_commuter_surveys_finalize_commuter_survey_confirmation: "Wollen Sie die Umfrage wirklich beenden?",
  customer_sco2pes_commuter_surveys_finalize_commuter_survey_warning: "Mitarbeiter können die Umfrage nicht mehr beantworten. Die CO<sub>2</sub>-Emissionen werden berechnet und fehlende Ergebnisse werden extrapoliert.",
  customer_sco2pes_commuter_surveys_data: "Daten",
  customer_sco2pes_commuter_surveys_submitted_answers: "Übermittelte Antworten",
  customer_sco2pes_commuter_surveys_expected_answers: "Erwartete Antworten",
  customer_sco2pes_commuter_surveys_co2_emissions: "CO<sub>2</sub> Emissionen",
  customer_sco2pes_commuter_surveys_submitted_co2_emissions: "Übermittelte CO<sub>2</sub> Emissionen",
  customer_sco2pes_commuter_surveys_extrapolated_co2_emissions: "Extrapolierte CO<sub>2</sub> Emissionen",
  customer_sco2pes_commuter_surveys_commuting_type: "Transportmittel",
  customer_sco2pes_commuter_surveys_distance_submitted: "Übermittelte Entfernung",
  customer_sco2pes_commuter_surveys_distance_extrapolated: "Extrapolierte Entfernung",
  customer_sco2pes_commuter_surveys_public_answer_page_description: "Vielen Dank für die Teilnahme an der Pendler:innen-Umfrage von <b>{companyName}</b> für das Jahr <b>{year}</b>.",
  customer_sco2pes_commuter_surveys_working_days_per_week: "Arbeitstage pro Woche",
  customer_sco2pes_commuter_surveys_thank_you: "Vielen Dank!",
  customer_sco2pes_commuter_surveys_public_answering_message: "Vielen Dank für Ihre Teilnahme an der Pendler:innen-Umfrage.<br>Ihr CO<sub>2</sub>-Fußabdruck beim Pendeln ist {0} kg für das Jahr {1}.<br><br><b>Sie können die Seite nun schließen.</b>",
  customer_sco2pes_commuter_surveys_survey_is_not_available: "Umfrage ist nicht verfügbar",
  customer_sco2pes_commuter_surveys_copy_to_clipboard: "Zwischenablage kopieren",
  customer_sco2pes_commuter_surveys_combined_commuter_survey_warning: "Pendler:innen Umfragen, die Teil einer kombinierten Pendler:innen Umfrage sind, können mit der kombinierten Pendler:innen Umfrage beendet / abgebrochen werden werden.",
  customer_sco2pes_commuter_surveys_link_copied: "Link Kopiert",
  customer_sco2pes_commuter_surveys_create_a_commuter_survey: "Pendlerumfrage erstellen",
  customer_sco2pes_commuter_surveys_max_distance_filter_km: "Maximale Distanz (km)",
  customer_sco2pes_commuter_surveys_included_transportation_types: "Enthaltene Transportarten",
  customer_sco2pes_commuter_surveys_commuter_survey_answered_warning: "Diese Pendler:innen-Umfrage wurde bereits in diesem Browser beantwortet.<br> Bitte seien Sie sich bewusst, dass Sie bereits übermittelte Antworten nicht mehr ändern können. Statt dessen wird eine neue Antwort übermittelt.<br>Sollten sie bemerkt haben, dass Sie falsche Daten eingegeben haben, wenden Sie sich bitte an Ihren Systemadministrator.",
  customer_sco2pes_commuter_surveys_continue_to_survey: "Weiter zur Umfrage",
  customer_sco2pes_commuter_surveys_created_by: "Erstellt von",
  customer_sco2pes_commuter_surveys_with_warning: "Mit Warnung",
  customer_sco2pes_commuter_surveys_without_warning: "Ohne Warning",
  customer_sco2pes_commuter_surveys_included: "Inkludiert",
  customer_sco2pes_commuter_surveys_excluded: "Exkludiert",
  customer_sco2pes_commuter_surveys_anonymous: "Anonym",
  customer_sco2pes_commuter_surveys_admins: "Admins",
  customer_sco2pes_commuter_surveys_exclude_answer_confirm: "Wollen Sie die Antwort wirklich von der Umfrage ausschließen?",
  customer_sco2pes_commuter_surveys_include_answer_confirm: "Wollen Sie die Antwort wirklich in die Umfrage aufnehmen?",
  customer_sco2pes_commuter_surveys_max_distance_filter_tooltip: "Maximale einfache Entfernung beim Pendeln. Wird diese Entfernung überschritten, werden die Antworten automatisch mit einer Warnung versehen.",
  customer_sco2pes_commuter_surveys_go_to_global_climate: "Weiter zu GLOBAL CLIMATE",
  customer_sco2pes_commuter_surveys_days: "Arbeitstage",
  customer_sco2pes_commuter_surveys_included_remote_working_type: "Enthaltener Home Office Typ",
  customer_sco2pes_commuter_surveys_included_remote_working_type_description: "Entscheiden Sie selbst, welchen CO<sub>2</sub>-Typ Sie in der Pendler Umfrage verwenden möchten.<br>Sie können einen eigenen CO<sub>2</sub>-Typ für Home Office mit Scope 3.7 in der CO<sub>2</sub>-Datenbank anlegen, dieser ist dann hier auswählbar.",
  customer_sco2pes_commuter_surveys_remote_working_co2_type: "Home Office CO<sub>2</sub>-Typ",
  customer_sco2pes_commuter_surveys_remote_working_type: "Home Office",
  customer_sco2pes_commuter_surveys_days_submitted: "Übermittelte Tage",
  customer_sco2pes_commuter_surveys_days_extrapolated: "Extrapolierte Tage",
  customer_sco2pes_commuter_surveys_on_site: "Vor Ort",
  customer_sco2pes_commuter_surveys_remote_work: "Mobiles Office",
  customer_sco2pes_commuter_surveys_daily_one_way_distance: "Tägliche einfache Strecke zur Arbeit",
  customer_sco2pes_commuter_surveys_one_way_distance: "Einfache Strecke (km)",
  customer_sco2pes_commuter_surveys_what_means_of_transportation: "Welche Transportmittel nutzen Sie durchschnittlich?",
  customer_sco2pes_commuter_surveys_transportation_type_info: "Sie können mittels der Slider die prozentuale Nutzung der Transportmittel angeben.",
  customer_sco2pes_commuter_surveys_transportation_type_example_1: "15 km einfache Distanz zur Arbeit <ul><li>3 Monate im Jahr mit dem Fahrrad</li><li>9 Monate im Jahr mit dem Auto</li></ul> Resultiert in Fahrrad = 25%, Auto = 75%",
  customer_sco2pes_commuter_surveys_transportation_type_example_2: "30 km einfache Distanz zur Arbeit <ul><li>12 km mit dem Bus</li><li>18 km mit dem Zug</li></ul> Resultiert in Bus = 40 %, Zug = 60 %",
  customer_sco2pes_commuter_surveys_transportation_type_example_3: "10 km einfache Distanz zur Arbeit <ul><li>3 Monate im Jahr volle Distanz mit dem Fahrrad</li><li>9 Monate im Jahr<ul><li>2 km zu Fuß</li><li>8 km mit dem Bus</li></ul></li></ul> Resultiert in Fahrrad = 25 %, zu Fuß = 15 %, Bus = 60 %.",
  customer_sco2pes_commuter_surveys_show_examples: "Beispiele Anzeigen",
  customer_sco2pes_commuter_surveys_examples: "Beispiele",
  customer_sco2pes_commuter_surveys_one_way_distance_warning: "Die einfache Strecke zur Arbeit muss größer 0 sein wenn vor Ort gearbeitet wird.",
  customer_sco2pes_commuter_surveys_slider_values_warning: "Die Summe der Prozente muss genau 100% betragen. Momentaner Wert: {0}%.",
  customer_sco2pes_commuter_surveys_combined: "Kombiniert",
  customer_sco2pes_commuter_surveys_organisation_unit_selection: "Auswahl der Organisation",
  customer_sco2pes_commuter_surveys_commuter_behaviour: "Pendler:innen-Verhalten",
  customer_sco2pes_commuter_surveys_welcome_text: "Herzlich Willkommen zur Pendler:innen-Umfrage von <b>{name}</b> für das Jahr <b>{year}</b>.",
  customer_sco2pes_commuter_surveys_select_organisation_unit: "Bitte wählen Sie die Organisation aus, der Sie angehören:",
  customer_sco2pes_commuter_surveys_select_organisation: "Organisationsauswahl",
  customer_sco2pes_commuter_surveys_please_indicate: "Bitte geben Sie Ihr Pendler:innen-Verhalten für das Jahr <b>{year}</b> und die ausgewählte Organisation <b>{unitName}</b> an",
  customer_sco2pes_commuter_surveys_expand_all: "Alle erweitern",
  customer_sco2pes_commuter_surveys_collapse_all: "Alle einklappen",

  // Dashboard
  customer_sco2pes_dashboard_alert_1: "Achtung! Emissionen werden alle 15 Minuten neu errechnet.",
  customer_sco2pes_dashboard_alert_2: "Aktuelle Änderungen der Stammdaten und Buchungsdaten werden möglicherweise nicht sofort auf dem Dashboard angezeigt.",
  customer_sco2pes_dashboard_alert_3: "Neu Berechnen",
  customer_sco2pes_dashboard_description: "Beschreibung",
  customer_sco2pes_dashboard_category_name: "Bezeichnung",
  customer_sco2pes_dashboard_type_name: "Typ",
  customer_sco2pes_dashboard_total_scope: "Summe Scope",
  customer_sco2pes_dashboard_emissions: "Emissionen",
  customer_sco2pes_dashboard_detailed_co2_emissions_in_kg: "Emissionen pro Scope (detailliert) in kg CO<sub>2</sub>e",
  customer_sco2pes_dashboard_total_CO2_emissions_per_scope_in_kg: "Emissionen pro Scope in kg CO<sub>2</sub>e",
  customer_sco2pes_dashboard_comparison: "Vergleich",
  customer_sco2pes_dashboard_co2_in_kg: "kg CO<sub>2</sub>e",
  customer_sco2pes_dashboard_kg: "kg",
  customer_sco2pes_dashboard_start_of_month: "Start des Monats",
  customer_sco2pes_dashboard_end_of_month: "Ende des Monats",
  customer_sco2pes_dashboard_supplier: "Lieferant",
  customer_sco2pes_dashboard_delete_chart: "Löschen",
  customer_sco2pes_dashboard_chart: "Diagramm",
  customer_sco2pes_dashboard_chart_select_first_1000_company_codes: "Erste 1000 Firmencodes auswählen",
  customer_sco2pes_dashboard_delete_chart_question: "Möchten Sie das Diagramm wirklich löschen?",
  customer_sco2pes_dashboard_chart_added_successfully: "Diagramm wurde erfolgreich hinzugefügt",
  customer_sco2pes_dashboard_chart_updated_successfully: "Diagramm wurde erfolgreich aktualisiert",
  customer_sco2pes_dashboard_chart_deleted_successfully: "Diagramm wurde erfolgreich gelöscht",
  customer_sco2pes_dashboard_chart_title: "Titel",
  customer_sco2pes_dashboard_chart_legend_position: "Legendenposition",
  customer_sco2pes_dashboard_chart_legend_position_top: "oben",
  customer_sco2pes_dashboard_chart_legend_position_bottom: "Unterseite",
  customer_sco2pes_dashboard_chart_legend_position_left: "Links",
  customer_sco2pes_dashboard_chart_legend_position_right: "Recht",
  customer_sco2pes_dashboard_chart_move_up: "Bewegen Sie sich nach oben",
  customer_sco2pes_dashboard_chart_move_down: "Sich abwärts bewegen",
  customer_sco2pes_dashboard_chart_save_required: "Bitte geben Sie alle Werte an",
  customer_sco2pes_dashboard_chart_type: "Diagramm Typ",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_COMPANY: "Ausgaben pro Unternehmen",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_COMPANY_PER_ORDER_PERIOD: "Emissionen im Laufe der Zeit",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_SCOPE_CATEGORY: "Emissionen pro THG-Scope-Kategorie",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_SCOPE_CATEGORY_PER_ORDER_PERIOD: "Emissionen pro THG-Scope-Kategorie im Zeitverlauf",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_SCOPE: "Emissionen pro THG-Scope",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_SCOPE_PER_ORDER_PERIOD: "Emissionen pro THG-Scope im Zeitverlauf",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_COMPANY_CODE: "Emissionen nach Buchungskreis",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_COMPANY_CODE_PER_ORDER_PERIOD: "Emissionen pro Buchungskreis im Zeitverlauf",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_SUPPLIER: "Emissionen pro Lieferant",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_SUPPLIER_PER_ORDER_PERIOD: "Emissionen pro Lieferant im Zeitverlauf",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_CO2_CATEGORY: "Emissionen pro CO2-Kategorie",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_CO2_CATEGORY_PER_ORDER_PERIOD: "Emissionen pro CO2-Kategorie im Zeitverlauf",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_CO2_TYPE: "Emissionen pro CO2-Typ",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_CO2_TYPE_PER_ORDER_PERIOD: "Emissionen pro CO2-Typ im Zeitverlauf",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_TRANSPORT_ROUTE: "Emissionen pro Transportweg",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_TRANSPORT_ROUTE_PER_ORDER_PERIOD: "Emissionen pro Transportweg im Zeitverlauf",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_UNIT: "Emissionen pro Einheit",
  customer_sco2pes_dashboard_chart_type_EMISSION_PER_UNIT_PER_ORDER_PERIOD: "Emissionen pro Einheit im Zeitverlauf",
  customer_sco2pes_dashboard_create_chart: "Neues diagramm",
  customer_sco2pes_dashboard_edit_chart: "Diagramm bearbeiten",
  customer_sco2pes_dashboard_all: "Alle",
  customer_sco2pes_dashboard_export_as_excel_file: "Exportieren als Excel-Datei",
  customer_sco2pes_dashboard_co2_relevant_warning: "Der ausgewählte Lieferant wurde explizit aus dem CO<sub>2</sub> report ausgeschlossen. Daher werden nur die Emissionen aus den Transportwegen im CO<sub>2</sub> Report beachtet.<br>Der Lieferant kann vollständig in den CO<sub>2</sub> Report eingeschlossen werden indem dieser als CO<sub>2</sub> relevant markiert wird.",
  customer_sco2pes_dashboard_no_data_available: "Es liegen keine CO<sub>2</sub> Buchungen für die angewandten Filter vor",
  customer_sco2pes_dashboard_customizable: "Dashboard",
  customer_sco2pes_dashboard_company: "Unternehmen",
  customer_sco2pes_dashboard_holdings: "Beteiligungen",
  customer_sco2pes_dashboard_holding_direct_indirect: "Beteiligung (direkt/indirekt)",
  customer_sco2pes_dashboard_no_parent_companies: "Keine Muttergesellschaften",
  customer_sco2pes_dashboard_no_subsidiary_companies: "Keine Tochtergesellschaften",
  customer_sco2pes_dashboard_subsidiary_emissions: "Von Tochtergesellschaften gemeldete Emissionen",
  customer_sco2pes_dashboard_parent_emissions: "An Muttergesellschaften gemeldete Emissionen",
  customer_sco2pes_dashboard_not_part_of_group: "Ihr Unternehmen ist nicht Teil einer Unternehmensgruppe, daher können keine CO<sub>2</sub> Emissionen angezeigt werden.",
  customer_sco2pes_dashboard_show_detailed_emissions: "Show detailed emissions",
  customer_sco2pes_dashboard_dashboard_configuration: "Dashboard Konfiguration",
  customer_sco2pes_dashboard_data_series: "Datenreihe",
  customer_sco2pes_dashboard_series_name: "Datenreihe Namen",
  customer_sco2pes_dashboard_series_colour: "Datenreihe Farbe",
  customer_sco2pes_dashboard_apply: "Anwenden",
  customer_sco2pes_company_group_export_excel: "Excel vereinfacht exportieren",
  customer_sco2pes_company_group_compare_all: "Alle vergleichen",
  customer_sco2pes_company_group: "Unternehmensgruppe",
  customer_sco2pes_company_group_emission_details: "Emissionsdetails",
  customer_sco2pes_company_group_export_including_own_company: "Export einschließlich eigener Firma",
  customer_sco2pes_company_group_export_without_own_company: "Export ohne eigene Firma",
  customer_sco2pes_company_group_select_all_subsidiaries: "Alle Tochtergesellschaften auswählen",
  customer_sco2pes_company_group_own_company: "Eigenes Unternehmen",
  customer_sco2pes_company_group_subsidiary: "Tochtergesellschaft",
  customer_sco2pes_filter_results_by_category: "Ergebnisse nach Kategorie filtern",
  customer_sco2pes_financial_model_amount: "Betrag",
  customer_sco2pes_materials_scopes_info_tooltip: "Gemäß dem GHG-Protokoll gibt es drei verschiedene Scopes, die verwendet werden, um die Treibhausgasemissionen eines Unternehmens zu kategorisieren und zu berichten. Diese Scopes werden als Scope 1, Scope 2 und Scope 3 bezeichnet und umfassen unterschiedliche Emissionsquellen und Kontrollbereiche.<br>",
  customer_sco2pes_materials_scopes_info_tooltip_more_details: "Für mehr Details auf Info Icon klicken.",
  customer_sco2pes_no_emission_equivalent_data_found: "Keine Daten für Emissionen pro Einheit gefunden",
  customer_sco2pes_suppliers_transport_route_info: "Informationen zum Transportweg",

  // Finalised reports
  customer_sco2pes_finalised_reports_title: "Berichte",
  customer_sco2pes_finalised_reports_subtitle: "Hier sind alle Berichte aufgelistet. Pro Bericht können angehängte Dateien und die Emissionsberechnungen heruntergeladen werden.",
  customer_sco2pes_finalised_report: "Bericht",
  customer_sco2pes_finalised_reports_finalise_report: "Bericht fertigstellen",
  customer_sco2pes_finalised_reports_successfully_created: "Bericht wurde erfolgreich erstellt",
  customer_sco2pes_finalised_reports_start_of_month: "Monatsanfang",
  customer_sco2pes_finalised_reports_end_of_month: "Ende des Monats",
  customer_sco2pes_finalised_reports_add_dialog_title: "Fertigstellung des Berichts",
  customer_sco2pes_finalised_reports_add_dialog_text: `
    Bitte wählen Sie einen Datumsbereich aus. Alle Unit Buchungen und Finanzbuchungen werden gesperrt. Diese können dann nicht mehr geändert werden. Alle in CCF vorgenommenen Änderungen haben keine Auswirkungen auf den Bericht.
    <b>Bitte überprüfen Sie alle Buchungen in der Buchungssektion der Software, um sicherzustellen, dass der Bericht abgeschlossen werden kann.</b>
  `,
  customer_sco2pes_finalised_reports_add_dialog_alert_title: "Hinweis zu ausstehenden Unit-Buchungen und ausstehenden Finanzbuchungen",
  customer_sco2pes_finalised_reports_add_dialog_alert_subtitle: "Bitte stellen Sie sicher, dass Sie alle möglichen ausstehenden Einheiten- und Finanzbuchungen aktivieren, bevor Sie den Bericht abschließen, da nach diesem Vorgang keine ausstehenden Buchungen aus dem ausgewählten Datumsbereich mehr aktiviert werden dürfen. Darüber hinaus speichert das System KEINE zugehörigen Informationen für ausstehende Buchungen.",
  customer_sco2pes_finalised_reports_add_dialog_add_emission_export_files: "Fügen Sie den Emissions-Excel-Bericht für den ausgewählten Datumsbereich zu zusätzlichen Dateien hinzu (optional)",
  customer_sco2pes_finalised_reports_add_dialog_terms: "Ich habe alle Buchungen gelesen und überprüft und möchte fortfahren.",
  customer_sco2pes_finalised_reports_delete_dialog_title: "Alle Buchungen freischalten",
  customer_sco2pes_finalised_reports_delete_dialog_text: "Entsperren Sie alle Buchungen zwischen {from} (Monatsanfang) und {until} (Monatsende).",
  customer_sco2pes_finalised_reports_delete_dialog_alert_title: `Hochgeladene Dateien`,
  customer_sco2pes_finalised_reports_delete_dialog_alert_subtitle: `Auf die Dateien kann nicht mehr zugegriffen werden. Erstellen Sie unbedingt eine Sicherungskopie und bewahren Sie diese auf dem gemeinsam genutzten Speicher des Unternehmens auf.`,
  customer_sco2pes_finalised_reports_successfully_deleted: "Buchungen erfolgreich freigeschaltet",
  customer_sco2pes_finalised_reports_details_dialog_title: "Bericht Dateien",
  customer_sco2pes_finalised_reports_details_dialog_no_files: "Keine Dateien an den Report angehängt",
  customer_sco2pes_finalised_reports_details_dialog_add_file: "Datei hinzufügen",
  customer_sco2pes_finalised_reports_details_dialog_select_file: "DATEI AUSWÄHLEN",
  customer_sco2pes_finalised_reports_details_dialog_upload: "HOCHLADEN",
  customer_sco2pes_finalised_reports_details_dialog_table_title: "Titel",
  customer_sco2pes_finalised_reports_details_dialog_table_created_by: "Erstellt von",
  customer_sco2pes_finalised_reports_details_dialog_table_created_at: "Erstellt bei",
  customer_sco2pes_finalised_reports_details_dialog_no_file_selected: "Keine Datei ausgewählt",
  customer_sco2pes_finalised_reports_details_dialog_input_label_title: "Titel",
  customer_sco2pes_finalised_reports_details_dialog_upload_failed: "Die endgültige Berichtsdatei konnte nicht hochgeladen werden",
  customer_sco2pes_finalised_reports_details_dialog_upload_validation: "Bitte geben Sie sowohl den Titel als auch die Datei zum Hochladen an",
  customer_sco2pes_finalised_reports_details_dialog_delete_file_question: "Möchten Sie die Datei wirklich löschen?",
};
